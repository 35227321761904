
import React, {useState} from "react"

import Layout from "../components/layout"
import WebappsMenupageComponent from "../components/webapps/components/menupage";


const ReportsPage = ({location}) => {
	const [token, setToken] = useState("");
	const [userinfo, setUserinfo] = useState({});
	const [menusource, setMenusource] = useState({});

	const menusrc = userinfo.hasOwnProperty("accessid")?(menusource.hasOwnProperty("reports"+userinfo.accessid)?menusource["reports"+userinfo.accessid]:[]):[];

	return <Layout location={location}
			newUserMenuHandler={(newtoken, newuserinfo, newmenusource)=>{setToken(newtoken); setUserinfo(newuserinfo); setMenusource(newmenusource);}}
			privatePage={true} usegatedcontent={true}>
			{(menusrc.length > 0 && token.length > 0) ? <>
				<WebappsMenupageComponent
						menusrc={menusrc}
						title={"Reports"}
						urlprefix={location.pathname}
					/>
			</>
			:<p class="underconstruction font-size-large">
				{token.length > 0?"No items found":"Loading..."}
			</p>
			}

		</Layout>

}


export default ReportsPage;
